var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Row',{attrs:{"gutter":10}},[_c('Col',{attrs:{"span":"2"}},[_c('Input',{attrs:{"placeholder":"姓名","clearable":""},model:{value:(_vm.searchKey.user_nick),callback:function ($$v) {_vm.$set(_vm.searchKey, "user_nick", $$v)},expression:"searchKey.user_nick"}})],1),_c('Col',{attrs:{"span":"2"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("查询")])],1),_c('Col',{staticStyle:{"text-align":"right"},attrs:{"span":"18"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("新增")])],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{attrs:{"span":"24"}},[_c('Table',{attrs:{"stripe":"","border":"","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var index = ref.index;
return [_vm._v(_vm._s(index + 1))]}},{key:"zone",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},_vm._l((row.zone),function(item){return _c('span',{key:item.zone_code},[_vm._v(_vm._s(item.zone_name)+"|")])}),0)]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.user_level < 1)?_c('Tag',{attrs:{"color":"red","size":"large"}},[_vm._v("禁用")]):_c('Tag',{attrs:{"color":"green","size":"large"}},[_vm._v("正常")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.detail(row.user_id)}}},[_vm._v("详情")])]}}])})],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('Page',{attrs:{"show-sizer":"","show-elevator":"","show-total":"","total":_vm.total,"current":_vm.searchKey.page,"page-size":30},on:{"on-change":_vm.pageChange,"on-page-size-change":_vm.pageSizeChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }